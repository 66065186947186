import React from "react"

const encode = (data) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { name: "", email: "", message: "" };
  }

  handleSubmit = e => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...this.state })
    })
      .then(() => {
        this.setState({
          message: "",
          email: "",
          name: ""
        });
        alert("Thank you for contacting!");
      })
      .catch(error => alert(error));

    e.preventDefault();
  };

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  render() {
    const { name, email, message } = this.state;
    return (
      <form
        style={{ marginBottom: "32px" }}
        name="contact"
        netlify-honeypot="bot-field"
        data-netlify="true"
        data-form-output="form-output-global"
        data-form-type="contact"
        onSubmit={this.handleSubmit}
      >
        <input type="hidden" name="bot-field"  aria-label="Form bot related"/>
        <input type="hidden" name="form-name" value="contact" aria-label="Form related"/>
        <div className="row gtr-uniform gtr-50">
          <div className="col-6 col-12-xsmall">
            <input
              className="form-input"
              type="text"
              name="name"
              data-constraints="@Required"
              value={name}
              placeholder="Your name"
              onChange={this.handleChange}
            />
          </div>
        
        <div className="col-6 col-12-xsmall">
            <input
              type="email"
              name="email"
              data-constraints="@Email @Required"
              value={email}
              placeholder="Your email"
              onChange={this.handleChange}
            />
        </div>
        <div className="col-12">
            <textarea
              name="message"
              value={message}
              placeholder="Your message"
              onChange={this.handleChange}
              data-constraints="@Required"
              rows="4"
            />
        </div>
        </div>
        <ul className="actions" style={{ marginTop: "32px" }}>
          <li>
            <button
              className="actions button"
              type="submit">
              Send
              </button>
              </li>
        </ul>

      </form>
    );
  }
}

export default ContactForm
