 import { useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Footer from './footer';
import Img from "gatsby-image"

const Header = ({ siteTitle }) =>{
  const data = useStaticQuery(
    graphql`
    query {
      file(relativePath: { eq: "avatar.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    `
  );
  return (
  <header id="header">
  <div className="inner">
    <Img className="image avatar"  fluid={data.file.childImageSharp.fluid}></Img>
    <h1><strong>Constantin Malii</strong><br /></h1>
  </div>
  <Footer />
</header>
);
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
