import React from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"
import Header from "./header"
import ContactForm from "./contactForm"
import Img from "gatsby-image"

const Layout = ({ children }) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
        file(relativePath: { eq: "01.jpg" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div id="main">
        <section id="one">
          <header className="major">
            <h2>About me</h2>
          </header>
          <p>
            I am a pfessional software developer from Calgary, Canada. I write software for
            work and enjoyment. Most recently, I am involved in building high scale distributed systems 
            and I build websites with GatsbyJS. 
          </p>
        </section>

        <section id="two">
          <header className="major">
            <h2>What I do</h2>
          </header>
          <p>
            I build well designed, performant and easy to use software
            applications for web, mobile and desktop.
            <br />
            My main technical tools are .Net Framework, C#, ASP.NET Core, WPF,
            ReactJS, Javascript, NodeJS.
          </p>
        </section>

        <section id="three">
          <h2>Recent Work</h2>
          <div className="row">
            <article className="col-6 col-12-xsmall work-item">
              {/* <a
                href={data.file.childImageSharp.fluid}
                className="image fit thumb"
              > */}
                <Img fluid={data.file.childImageSharp.fluid} alt="" />
              {/* </a> */}
              <h3>WE Financial</h3>
              <p>
                Point of Sale Destop App built with C#, WPF, Entity Framework
                and the 6.2 version of the .Net Framework. Some .Net Core projects too. 
              </p>
            </article>
            {/* <article className="col-6 col-12-xsmall work-item">
                <a href="images/fulls/02.jpg" className="image fit thumb">
                  <img src="images/thumbs/02.jpg" alt="" />
                </a>
                <h3>Ultricies lacinia interdum</h3>
                <p>Lorem ipsum dolor sit amet nisl sed nullam feugiat.</p>
              </article>
              <article className="col-6 col-12-xsmall work-item">
                <a href="images/fulls/03.jpg" className="image fit thumb">
                  <img src="images/thumbs/03.jpg" alt="" />
                </a>
                <h3>Tortor metus commodo</h3>
                <p>Lorem ipsum dolor sit amet nisl sed nullam feugiat.</p>
              </article> */}
          </div>
        </section>

        <section id="four">
          <h2>Get In Touch</h2>
          <p>Send me a note! I will get back to you shortly</p>
          <div className="row">
            <div className="col-8 col-12-small">
              <ContactForm />
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}
export default Layout
