import React from 'react';

export default () => (
  <footer id="footer">
    <div className="inner">
      <ul className="icons">
        <li><a href="http://www.linkedin.com/in/constantinm" className="icon brands fa-linkedin"><span className="label">Linkedin</span></a></li>
        {/* <li><a href="#" className="icon brands fa-github"><span className="label">Github</span></a></li> */}
        <li><a href="#mailto:malii.constantin@gmail.com" className="icon solid fa-envelope"><span className="label">Send me an email</span></a></li>
      </ul>
      <ul className="copyright">
        <li>&copy; Constantin Malii</li>
      </ul>
    </div>
  </footer>
)
